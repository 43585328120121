import { z } from "zod"

import {
  ContactLanguageValue,
  ContactPortalValue,
  ContactSalutationValues,
  ContactTypeValues,
} from "../contact"

const phoneRegex = new RegExp("^[+]?[0-9() \\/.\\-]{1,21}[0-9]$")
const emailRegex = new RegExp(
  "^(?!\\.)(?!.*\\.\\.)([A-Z0-9_+-\\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\\-]*\\.)+[A-Z]{2,}$",
  "i"
)

const nameRegex = new RegExp("^([^0-9]*)$")

const contactRequestValidationSchema = z.object({
  message: z
    .string({ required_error: "contactAgency_sendMessageErrorFieldMessageMissing" })
    .min(1, { message: "contactAgency_sendMessageErrorFieldMessageMissing" })
    .max(500, { message: "contactAgency_sendMessageErrorFieldTooLongMessage" }),
  personContact: z.object({
    email: z
      .string({ required_error: "contactAgency_sendMessageErrorFieldEmailMissing" })
      // required_error will not be displayed if the field has been filled but then emptied again
      .min(1, { message: "contactAgency_sendMessageErrorFieldEmailMissing" })
      .regex(emailRegex, { message: "contactAgency_sendMessageErrorFieldWrongEmail" }),
    salutation: z.enum(ContactSalutationValues, {
      required_error: "contactAgency_sendMessageErrorFieldSalutationMissing",
    }),
    lastName: z
      .string({ required_error: "contactAgency_sendMessageErrorFieldLastNameMissing" })
      .min(1, { message: "contactAgency_sendMessageErrorFieldLastNameMissing" })
      .regex(nameRegex, { message: "contactAgency_sendMessageErrorFieldFormatLastName" }),
    firstName: z
      .string({ required_error: "contactAgency_sendMessageErrorFieldFirstNameMissing" })
      .min(1, { message: "contactAgency_sendMessageErrorFieldFirstNameMissing" })
      .regex(nameRegex, { message: "contactAgency_sendMessageErrorFieldWrongName" }),
    phone: z
      .string({ required_error: "contactAgency_sendMessageErrorFieldLastNameMissing" })
      .regex(phoneRegex, { message: "contactAgency_sendMessageErrorFieldWrongNumber" })
      .optional()
      .or(z.literal("")),
    language: z.enum(ContactLanguageValue).optional(),
  }),
})

export const ContactValidationSchema = z.object({
  portal: z.enum(ContactPortalValue),
  partnerId: z.string(),
  project: z.enum(ContactTypeValues),
  contactRequest: contactRequestValidationSchema,
})

export const ContactFormValidationSchema = z.object({
  contactRequest: contactRequestValidationSchema,
})

export const BffContactValidationSchema = z.object({
  body: ContactValidationSchema,
})

export type Form = z.infer<typeof ContactFormValidationSchema>
export type FormKey =
  | keyof Form["contactRequest"]["personContact"]
  | Exclude<keyof Form["contactRequest"], "personContact">
/**
 * In order of appearance in the form
 */
export const formKeyValues: readonly FormKey[] = [
  "salutation",
  "firstName",
  "lastName",
  "phone",
  "email",
  "message",
] as const
